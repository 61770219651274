/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import coupleDancing from '../images/heroes/couple-dancing.jpg'

import { StaticImage } from 'gatsby-plugin-image'
import CountdownTimer from '../components/Countdown'
import DynamicCTACard from '../components/DynamicCTACard'
import SeoCtaBar from '../components/SeoCtaBar'

import CobraBanner from '../components/CobraBanner'

import {
  Accordion,
  Columns,
  Column,
  Hero,
  LinkButton,
  List,
  ListItem,
  Stack,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../components/Footer'
import header from '../components/Header'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-aet-enrollment']

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            'Aetna Medicare Plan Enrollment | 855-200-5726 | Enroll Today!',
          description:
            'Do Medicare Enrollment Periods have you stumped? Get step-by-step guidance to enroll in an Aetna Medicare plan. To find out more about call 855-200-5726!',
        },
        path: '/enrollment',
        promoCode: '120779',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Aetna Medicare Plan Enrollment | 855-200-5726 | Enroll Today!',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero background-image-position-top"
              image={
                <img
                  src={coupleDancing}
                  alt="elderly couple dancing together in kitchen and smiling"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Medicare Plans Enrollment{' '}
                  </Typography>

                  <Typography variant="h4">
                    Learn all the ins and outs of enrolling in Medicare Plans
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>
        <SeoCtaBar />
        <CountdownTimer />
        <VariableContent
          mainContent={
            <>
              <Typography variant="h2">
                Get the Skinny on Enrollment in Medicare Plans
              </Typography>
              <Typography variant="body">
                Perhaps you’re signing up for Medicare for the first time, or
                maybe you’re already on Medicare and would like to enroll in
                additional coverage. Either way, the Medicare enrollment process
                can be confusing and intimidating.
              </Typography>
              <Typography variant="body">
                But not to fear—our licensed agents make enrolling in the right
                Medicare plan at the right time simple. Get answers to your
                enrollment questions by reading on or simply talk to a licensed
                agent today!
              </Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../images/icons/purple-checklist.svg"
                    alt="checklist icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare Enrollment Eligibility
                  </Typography>
                  <Typography variant="body">
                    Find out whether you’re eligible to enroll in Original
                    Medicare, Medicare Advantage, or other Medicare plans.
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="#am-i-eligible"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../images/icons/purple-calendar.svg"
                    alt="calendar icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare Enrollment Periods
                  </Typography>
                  <Typography variant="body">
                    Learn about Open Enrollment and other Medicare enrollment
                    periods, as well as when they apply to you.
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="#when-can-i-enroll"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../images/icons/purple-magnify.svg"
                    alt="magnifying glass icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare Enrollment How-To
                  </Typography>
                  <Typography variant="body">
                    Discover each and every step of the Medicare enrollment
                    process, no matter what type of plan you’re applying for.
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="#how-do-i-enroll"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent backgroundColor="lightGray" id="am-i-eligible">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-checklist.svg"
                alt="checklist icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                Am I Eligible to Enroll in Medicare Benefits?
              </Typography>
              <Typography variant="body">
                Medicare enrollment is mainly based on your age. The magic
                number is 65. The requirements for enrolling in Medicare change
                depending on whether you’re older or younger than 65. Here’s a
                brief overview.
              </Typography>
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion
                  items={[
                    {
                      title: "If You're 65 or Older",
                      content: (
                        <>
                          <Typography>
                            If you’re at least 65 years old (or soon will be)
                            and a citizen of the US who's been living in the
                            country for at least five years, chances are you’re
                            already eligible to enroll in Medicare Part A and
                            Part B (otherwise known as “Original Medicare”). You
                            may even qualify for what’s called “premium-free”
                            Medicare Part A if you meet the following
                            requirements:
                          </Typography>
                          <List>
                            <ListItem>
                              You or your spouse have worked and paid Medicare
                              taxes for at least 10 years before you enroll
                            </ListItem>
                            <ListItem>
                              You or your spouse are eligible to receive Social
                              Security or Railroad Retirement Board (RRB)
                              benefits
                            </ListItem>
                          </List>
                        </>
                      ),
                    },
                    {
                      title: "If You're Younger Than 65",
                      content: (
                        <>
                          <Typography>
                            Generally speaking, if you’re under the age of 65,
                            you are only eligible to receive Medicare benefits
                            if you have certain Medicare-recognized disabilities
                            or suffer from end-stage renal disease (otherwise
                            known as kidney failure or ESRD) or amyotrophic
                            lateral sclerosis (also called ALS or Lou Gehrig’s
                            disease). There are, however, different requirements
                            for each of these cases:
                          </Typography>
                          <List>
                            <ListItem>
                              <strong>If you have a disability,</strong> you
                              must Enroll in and receive Social Security
                              benefits for 24 consecutive months before you are
                              eligible to receive Medicare benefits. This isn’t
                              the case if you have ESRD or ALS.
                            </ListItem>
                            <ListItem>
                              <strong>If you have ESRD,</strong> you will
                              typically become eligible to enroll in Medicare
                              plans after a kidney transplant or three months
                              after you start your regular course of kidney
                              dialysis.
                            </ListItem>
                            <ListItem>
                              <strong>If you have ALS,</strong> you can enroll
                              in Medicare as soon as you start receiving Social
                              Security benefits.
                            </ListItem>
                          </List>
                        </>
                      ),
                    },
                  ]}
                />
              </Stack>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent id="when-can-i-enroll">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-calendar.svg"
                alt="calendar icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                When Can I Enroll in Medicare?
              </Typography>
              <Typography variant="body">
                There are several different Medicare enrollment periods, each
                with their own special requirements and circumstances. Browse
                the info below to learn when you can enroll in Medicare plans.
              </Typography>
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion
                  items={[
                    {
                      title: 'Initial Enrollment Period',
                      content: (
                        <>
                          <Typography>
                            Your Initial Enrollment Period (IEP) is the time
                            frame when you first become eligible for Medicare.
                          </Typography>
                          <Typography>
                            Your Initial Enrollment Period lasts seven months.
                            It starts three months before the month of your 65th
                            birthday and ends three months after that same
                            month. So, for example, if your birthday is on
                            August 10, your Initial enrollment period goes from
                            May 1 to November 30.
                          </Typography>
                          <Typography>
                            Now here’s where it gets a little tricky. If your
                            birthday falls on the first day of the month, then
                            Medicare counts the previous month as your month of
                            birth. In other words, if your birthday falls on
                            August 1, your Initial Enrollment Period begins
                            April 1 and ends October 31.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'General Enrollment Period',
                      content: (
                        <>
                          <Typography>
                            If for some reason you miss your Initial Enrollment
                            Period (IEP), you can enroll in Medicare policies
                            during the General Enrollment Period or GEP. The GEP
                            runs from January 1 to March 31 each year. Keep in
                            mind, if you miss your IEP and need to enroll during
                            the GEP, you may have to pay a late fee. If you use
                            this enrollment period, your Medicare coverage
                            begins on July 1.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Annual Enrollment Period',
                      content: (
                        <>
                          <Typography>
                            The Annual Enrollment Period or AEP starts October
                            15 and ends December 7 each year. This is the time
                            of year when you can enroll in new Medicare plans,
                            make changes to your existing Medicare coverage
                            elections, or cancel your policies. Any changes that
                            you make to your coverage will start January 1 the
                            following year.
                          </Typography>
                          <Typography>
                            If you like your current Medicare coverage and don’t
                            want to make any changes, then don’t worry. You
                            don’t need to do anything. You will be automatically
                            re-enrolled in your existing Medicare plan(s).
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Special Enrollment Period',
                      content: (
                        <>
                          <Typography>
                            Your Initial Enrollment Period and Annual Enrollment
                            Period aren’t the only times you can make changes to
                            your Medicare coverage. For example, if you’ve moved
                            out of your current plan’s area of coverage, if
                            you’re no longer eligible for Medicaid, or if you
                            live in a long-term care facility (such as a nursing
                            home), you may be eligible for what’s known as a
                            Special Enrollment Period or SEP.
                          </Typography>
                          <Typography>
                            For a complete{' '}
                            <a
                              href="https://www.medicare.gov/basics/get-started-with-medicare/get-more-coverage/joining-a-plan/special-enrollment-periods"
                              target="_blank"
                            >
                              list of special circumstances
                            </a>{' '}
                            that fall within the Special Enrollment Period, see
                            Medicare’s website.
                          </Typography>
                        </>
                      ),
                    },
                  ]}
                />
              </Stack>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent backgroundColor="lightGray" id="how-do-i-enroll">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-magnify.svg"
                alt="magnifying glass icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">How Do I Enroll in Medicare?</Typography>
              <Typography variant="body">
                There are a number of ways to enroll in Medicare plans.
                Depending on your situation, you may even qualify for automatic
                enrollment. Read on to learn more.
              </Typography>
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion
                  items={[
                    {
                      title: 'Medicare Part A',
                      content: (
                        <>
                          <Typography>
                            Depending on your situation, you may be eligible for
                            automatic enrollment in Medicare Part A. If this is
                            the case, then you can relax! You don’t have to do
                            anything, and your{' '}
                            <a href="/medicare-card">Medicare ID card</a> will
                            be automatically mailed to your home. You are
                            eligible for automatic enrollment in Medicare Part A
                            if one of the following applies to you:
                          </Typography>
                          <List>
                            <ListItem>
                              <strong>
                                You’re turning 65 and are already receiving
                                retirement benefits
                              </strong>{' '}
                              through the Social Security Administration or
                              Railroad Retirement Board (RRB). Expect your
                              Medicare ID card in the mail roughly three months
                              before you turn 65.
                            </ListItem>
                            <ListItem>
                              <strong>
                                You’re younger than 65, and you’ve been
                                receiving disability
                              </strong>{' '}
                              benefits through Social Security or the RRB for 24
                              consecutive months. Expect your Medicare card in
                              the mail about 25 months after you received your
                              first Social Security or RRB check.
                            </ListItem>
                            <ListItem>
                              <strong>
                                You have amyotrophic lateral sclerosis
                              </strong>{' '}
                              (ALS or Lou Gehrig’s disease). You’ll be
                              automatically enrolled in Medicare the same month
                              your Social Security disability benefits began,
                              and you should receive your ID card around the
                              same time.
                            </ListItem>
                            <ListItem>
                              <strong>
                                If you have end-stage renal disease
                              </strong>{' '}
                              (ESRD or kidney failure) or if you’re not
                              currently receiving Social Security or RRB
                              benefits, you’ll need to{' '}
                              <a href="/">enroll in Medicare Part A manually</a>
                              .
                            </ListItem>
                          </List>
                        </>
                      ),
                    },
                    {
                      title: 'Other Medicare Plans',
                      content: (
                        <>
                          <Typography>
                            If you want to sign up for Medicare Part B there are
                            a few different ways you can enroll, including
                            these:
                          </Typography>
                          <List>
                            <ListItem>
                              Online at{' '}
                              <a href="https://www.ssa.gov/" target="_blank">
                                www.SocialSecurity.gov
                              </a>
                            </ListItem>
                            <ListItem>
                              By phone at{' '}
                              <a href="tel:1-800-772-1213">1-800-772-1213</a>
                            </ListItem>
                            <ListItem>
                              In person at your local Social Security
                              Administration office
                            </ListItem>
                          </List>
                        </>
                      ),
                    },
                  ]}
                />
              </Stack>
            </Column>
          </Columns>
        </VariableContent>
        <CobraBanner />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
